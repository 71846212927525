export const MAX_NAME_LENGTH = 20;
export const MAX_DESCRIPTION_LENGTH = 200;



export const isValidName = (val: string) => {
    return val.length <= MAX_NAME_LENGTH;
};

export const isValidDescription = (val: string) => {
    return val.length <= MAX_DESCRIPTION_LENGTH;
};

export const isValidValue = (value: string) => {
    
    const INTEGER = new RegExp('^-?[0-9]{0,9}$');
    const FLOAT = new RegExp('^-?[0-9]{0,9}\\.[0-9]{0,2}$');

    const isInteger = INTEGER.test(value);
    const isFloat = FLOAT.test(value);

    return isInteger || isFloat;
};

