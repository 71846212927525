import InputTextTk from "../input-text/main";
import { isValidValue } from "../input-utils";

// ****************************************************************************
// PROPS

export interface Props {
    value: string;
    theme?: string;
    onChange: (val: string) => void;
};

// ****************************************************************************
// MAIN COMPONENT

export const InputValueTk = (props: Props) => {

    const inputProps = {
        ...props,
        isValid: isValidValue
    };

    return <InputTextTk {...inputProps} />;
};

export default InputValueTk;