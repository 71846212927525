import { COLORS } from "../../utility/colors";
import { FONTS } from "../../utility/fonts";
import { BORDER_RADIUS } from "../../utility/globals";

export const setInputDefaultTheme = () =>
    `
        background-color: ${COLORS.WHITE_PURE};
        color: ${COLORS.GRAY_SOFT};

        align-items: center;
        display: flex;

        font-type: ${FONTS.ROBOTO};
        font-size: 16px;

        padding: 5px 5px 5px 10px;

        border: 1px solid ${COLORS.GRAY_SOFT};
        border-radius: ${BORDER_RADIUS.STANDARD};
        box-sizing: border-box;

        min-height: 40px;
        width: 100%;

        &:focus-within {
            background-color: ${COLORS.WHITE_SOFT};
            border: 1px solid ${COLORS.GREEN_DEEP};
        }
    `