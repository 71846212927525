import { callProtectedApi } from "./api-util";

export type newTransaction = {
    aid: string,
    name: string,
    description: string,
    value: number
}

// @POST url-tag: /transaction
export const create = async (accessToken: string, transaction: newTransaction) => {
    const response = await callProtectedApi(accessToken, `POST`, `/transaction`, JSON.stringify(transaction));
    return response;
}

// @GET url-tag: /transaction/{tid}
export const get = (accessToken: string, tid: string) => {
    return callProtectedApi(accessToken, `GET`, `/transaction/${tid}`, null);
}

// @PUT url-tag: /transaction/{tid}
export const update = (accessToken: string, tid: string, transaction: any) => {
    return callProtectedApi(accessToken, `PUT`, `/transaction/${tid}`, JSON.stringify(transaction));
}

// @DELETE url-tag: /transaction/{tid}
export const remove = (accessToken: string, tid: string) => {
    return callProtectedApi(accessToken, `DELETE`, `/transaction/${tid}`, null);
}