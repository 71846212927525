import Stripe from 'stripe';

const STRIPE_SECRET_KEY = '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'

export const getSubscriptionStatus = async (stripeCustomerId: string) => {
    const stripe = new Stripe(STRIPE_SECRET_KEY, {apiVersion: '2023-08-16'});
    const subscriptions = await stripe.subscriptions.list({
        customer: stripeCustomerId,
    });

    if (subscriptions.data.length > 0) {
        return {status: subscriptions.data[0].status};
    };
    return {status: 'no-subscription'};
};