import { useAuth0 } from "@auth0/auth0-react";
import Questions from "./questions/main";
import Preparing from "./preparing/main";
import Payments from "./payments/main";
import Header from "./header/main";
import { useState } from "react";
import * as A from "./styled";

export const InitialBudgetSetup = () => {

    const { user } = useAuth0();
    const [step, setStep] = useState(2);

    const incrementStep = () => {
        setStep(step + 1);
    };

    const getContent = () => {
        return <Payments user={user}/>;

        if (step === 1) {
            return <Questions incrementStep={incrementStep} />;
        } else if (step === 2) {
            return <Preparing incrementStep={incrementStep}/>;
        } else {
            return <Payments user={user}/>;
        }
    }


    return (
        <A.Root>
          <A.ContentContainer>
            <Header stepNumber={step} />
            {getContent()}
          </A.ContentContainer>
        </A.Root>
    );
};

export default InitialBudgetSetup;