import { columnFlexboxFit, flexboxFit } from '../../../../utility/css-flexbox';
import { COLORS } from '../../../../utility/colors';
import { MEDIA } from '../../../../utility/media';
import styled from 'styled-components';

export const Root = styled.div`
    ${columnFlexboxFit({justify: 'center', align: 'center'})};
    max-width: 520px;
    min-width: 280px;
    width: 100%;

    padding-bottom: 40px;
    padding-top: 40px;
`

export const Title = styled.div`
    color: ${COLORS.GRAY_PURE};

    font-weight: bold;
    font-size: 24px;

    ${MEDIA.lessThan('s460')`
        font-size: 20px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 18px;
    `};
`

export const Description = styled.div`
    color: ${COLORS.GRAY_SOFT_1};
    
    font-weight: bold;
    font-size: 14px;

    ${MEDIA.lessThan('s460')`
        font-size: 12px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 10px;
    `};

    z-index: 1;
`

export const PlanRoot = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};

    padding-top: 32px;
    width: 100%;

    ${MEDIA.lessThan('s580')`
        ${columnFlexboxFit({justify: 'center', align: 'center'})};
    `};
`

export const FinePrint = styled.div`
    color: ${COLORS.GRAY_SOFT};
    
    text-align: center;
    font-size: 12px;

    padding: 12px;
    padding-top: 24px;
`

export const Spacer =styled.div`
    height: 8px;
    width: 8px;
`

export const ImageContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;

    margin-bottom: -16px;

`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
