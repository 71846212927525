import { getAccountPageUrl, getColumnPageUrl } from "../app-router/main";
import { useEffect, useState } from "react";
import { SIZES } from "./media";

export const isValidValue = (value: string) => {
    
    const INTEGER = new RegExp('^-?[0-9]{0,8}$');
    const FLOAT = new RegExp('^-?[0-9]{0,8}\\.[0-9]{0,2}$');

    const isInteger = INTEGER.test(value);
    const isFloat = FLOAT.test(value);

    return isInteger || isFloat;
};

export const str2Float = (val: string) => {
    if (val === '' || val === '-' || val === '.' || val === '-.') {
        return 0;
    };
    return parseFloat(val);
};

export const getFormattedValue = (value: number) => {
    
    const strNum = Math.abs(value).toFixed(2);
    var formatted = strNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    if(value < 0) {
        return "-$" + formatted;
    };

    return "$" + formatted;
};

export const getCompactFormattedValue = (value: number) => {
    
    let strNum = Math.abs(value).toFixed(2);
    if (strNum[strNum.length - 1] === '0' && strNum[strNum.length - 2] === '0') {
        strNum = strNum.slice(0, -3);
    }

    var formatted = strNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    if(value < 0) {
        return "-$" + formatted;
    };

    return "$" + formatted;
};

export const getFormattedDate = (date: string) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const _date = new Date(date);
    const day   = _date.getDate();
    const month = months[_date.getMonth()]
    const year  = _date.getFullYear()

    return day + " " + month + ", " + year;
}

export const getSidebarColumnItems = (state: any, navigate: any, account: any) => {
    const columns = getColumnsForAidFromState(state, account._id);
    const columnItems: any[] = [];

    columns?.forEach((column: any) => {
        columnItems.push({
            text: column.name,
            onClick: () => navigate(getColumnPageUrl(column._id))
        });
    });

    return columnItems.length > 0 ? columnItems : undefined;
 };

 export const getSidebarAccountItems = (state: any, navigate: any) => {
    const accounts = getAccountsFromState(state);
    const accountItems: any[] = [];

    accounts?.forEach((account: any) => {
        accountItems.push({
            text: account.name,
            children: getSidebarColumnItems(state, navigate, account),
            onClick: () => navigate(getAccountPageUrl(account._id))
        });
    });

    return accountItems.length > 0 ? accountItems : [];
 }

// ############################################################################
// VIEWPORT HELPERS

export const useViewportWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
}

export const useScreenLessThan = () => {
    const {width} = useViewportWidth();
    return {
        screenLt300:  width < Number(SIZES.S300),
        screenLt310:  width < Number(SIZES.S310),
        screenLt320:  width < Number(SIZES.S320),
        screenLt330:  width < Number(SIZES.S330),
        screenLt340:  width < Number(SIZES.S340),
        screenLt350:  width < Number(SIZES.S350),
        screenLt360:  width < Number(SIZES.S360),
        screenLt370:  width < Number(SIZES.S370),
        screenLt380:  width < Number(SIZES.S380),
        screenLt390:  width < Number(SIZES.S390),

        screenLt400:  width < Number(SIZES.S400),
        screenLt410:  width < Number(SIZES.S410),
        screenLt420:  width < Number(SIZES.S420),
        screenLt430:  width < Number(SIZES.S430),
        screenLt440:  width < Number(SIZES.S440),
        screenLt450:  width < Number(SIZES.S450),
        screenLt460:  width < Number(SIZES.S460),
        screenLt470:  width < Number(SIZES.S470),
        screenLt480:  width < Number(SIZES.S480),
        screenLt490:  width < Number(SIZES.S490),

        screenLt500:  width < Number(SIZES.S500),
        screenLt510:  width < Number(SIZES.S510),
        screenLt520:  width < Number(SIZES.S520),
        screenLt530:  width < Number(SIZES.S530),
        screenLt540:  width < Number(SIZES.S540),
        screenLt550:  width < Number(SIZES.S550),
        screenLt560:  width < Number(SIZES.S560),
        screenLt570:  width < Number(SIZES.S570),
        screenLt580:  width < Number(SIZES.S580),
        screenLt590:  width < Number(SIZES.S590),

        screenLt600:  width < Number(SIZES.S600),
        screenLt610:  width < Number(SIZES.S610),
        screenLt620:  width < Number(SIZES.S620),
        screenLt630:  width < Number(SIZES.S630),
        screenLt640:  width < Number(SIZES.S640),
        screenLt650:  width < Number(SIZES.S650),
        screenLt660:  width < Number(SIZES.S660),
        screenLt670:  width < Number(SIZES.S670),
        screenLt680:  width < Number(SIZES.S680),
        screenLt690:  width < Number(SIZES.S690),

        screenLt700:  width < Number(SIZES.S700),
        screenLt710:  width < Number(SIZES.S710),
        screenLt720:  width < Number(SIZES.S720), 
        screenLt780:  width < Number(SIZES.S780), 
        screenLt840:  width < Number(SIZES.S840), 
        screenLt900:  width < Number(SIZES.S900), 
        screenLt960:  width < Number(SIZES.S960), 
        screenLt1020: width < Number(SIZES.S1020), 
        screenLt1080: width < Number(SIZES.S1080), 
        screenLt1140: width < Number(SIZES.S1140), 
        screenLt1200: width < Number(SIZES.S1200), 
        screenLt1260: width < Number(SIZES.S1260), 
        screenLt1320: width < Number(SIZES.S1320), 
        screenLt1380: width < Number(SIZES.S1380), 
        screenLt1440: width < Number(SIZES.S1440), 
        screenLt1500: width < Number(SIZES.S1500), 
        screenLt1560: width < Number(SIZES.S1560)  
    };
};

export const useScreenLtXSmall = () => {
    const {width} = useViewportWidth();
    const screenLtXSmall = width <= Number(SIZES.S420);
    return { screenLtXSmall };
};

export const useScreenLtSmall = () => {
    const {width} = useViewportWidth();
    const screenLtSmall = width <= Number(SIZES.S720);
    return { screenLtSmall };
};

export const useScreenLtRegular = () => {
    const {width} = useViewportWidth();
    const screenLtRegular = width <= Number(SIZES.S1080);
    return { screenLtRegular };
};

export const useScreenLtXLarge = () => {
    const {width} = useViewportWidth();
    const screenLtXLarge = width <= Number(SIZES.S1560);
    return { screenLtXLarge };
};

// ############################################################################
// STATE GETTERS

export const getAccountFromState = (state: any, aid: string) => {

    const accounts = state.project.accounts;
    const account = accounts?.find((a: any) => {
        return a._id  === aid;
    });

    return account;
}

// ####################################

export const getAccountsFromState = (state: any) => {
    return state.project.accounts;    
}

// ####################################

export const getColumnFromState = (state: any, cid: string) => {

    const columns = state.project.columns;
    const column = columns?.find((c: any) => {
        return c._id  === cid;
    });

    return column;
}

// ####################################

export const getColumnsForAidFromState = (state: any, aid: string) => {

    const columns = state.project.columns; 
    return columns.filter((c: any) => c.aid === aid);
}

// ####################################

export const getTransactionsForAidFromState = (state: any, aid: string) => {

    const transactions = state.project.transactions; 
    return transactions.filter((t: any) => t.aid === aid);
}

// ####################################

export const getTransactionsForCidFromState = (state: any, cid: string) => {

    const transactions = state.project.transactions; 
    return transactions.filter((t: any) => t.cid === cid);
}

// ############################################################################
// STATE CALCULATIONS

export const getAccountValue = (state: any, aid: string) => {
    
    let accountValue = 0;

    const columns = getColumnsForAidFromState(state, aid);
    columns.forEach((c: any) => {
        accountValue = accountValue + c.value;
    });

    return accountValue; 
}

// ############################################################################
// URL HELPERS

export const getUrl = () => {
    const urlSplit = window.location.href.split('?');

    if (urlSplit.length === 1) {
        return urlSplit[urlSplit.length - 1];
    };
    return urlSplit[urlSplit.length - 2];
};

export const getParameters = () => {
    const urlSplit = window.location.href.split('?');

    if (urlSplit.length === 1) {
        return null;
    };
    return urlSplit[urlSplit.length - 1];
};

export const isMyBudgetUrl = () => {
    const urlSplit = getUrl().split('/');
    return urlSplit[urlSplit.length - 1] === "mybudget";   
};

export const isAccountUrl = () => {
    const urlSplit = getUrl().split('/');
    return urlSplit[urlSplit.length - 2] === "account";   
}

export const isAccountSummaryUrl = () => {
    const urlSplit = getUrl().split('/');
    const params = getParameters();

    return urlSplit[urlSplit.length - 2] === "account" && params && params === 'view=summary';   
}

export const isColumnUrl = () => {
    const urlSplit = getUrl().split('/');
    return urlSplit[urlSplit.length - 2] === "column";   
}

export const getGuideNameFromUrl = () => {
    const urlSplit = getUrl().split('/');
    return urlSplit[urlSplit.length - 1];
}

// ####################################

export const getAidFromUrl = (state: any) => {
    
    const urlSplit = getUrl().split('/');

    const type = urlSplit[urlSplit.length - 2];
    const xid = urlSplit[urlSplit.length - 1];

    // if account url - return aid directly
    if (type === 'account') {
        return xid
    }
    
    // if column url - derive aid from cid
    const column = getColumnFromState(state, xid);
    const aid = column.aid;

    return aid;
}

// ####################################

export const getAccountFromUrl = (state: any) => {
    const aid = getAidFromUrl(state);
    return getAccountFromState(state, aid);
} 

// ####################################

export const getCidFromUrl = (state: any) => {
    
    const urlSplit = getUrl().split('/');
    const cid = urlSplit[urlSplit.length - 1];
    return cid;
}

// ####################################

export const getColumnFromUrl = (state: any) => {

    if (isColumnUrl() || isAccountUrl()) {
        const aid = getCidFromUrl(state);
        return getColumnFromState(state, aid);
    };

    return null;
} 

// ####################################

export const getColumnsFromUrl = (state: any) => {
    
    if (isColumnUrl() || isAccountUrl()) {
        const aid = getAidFromUrl(state);
        return getColumnsForAidFromState(state, aid);
    };

    return [];
}

// ####################################

export const getTransactionsFromUrl = (state: any) => {
    
    const urlSplit = getUrl().split('/');

    const type = urlSplit[urlSplit.length - 2];
    const xid = urlSplit[urlSplit.length - 1];

    // if account url - return aid directly
    if (type === 'account') {
        return getTransactionsForAidFromState(state, xid);
    }

    return getTransactionsForCidFromState(state, xid);
}

// ####################################
// DATE UTIL

export const getCleanDate = () => {
    const date = new Date();

    date.setHours(12);
    date.setMinutes(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

// ####################################



