import { COLORS } from '../../../../utility/colors';
import { columnFlexboxFit } from '../../../../utility/css-flexbox';
import styled from 'styled-components';
import { BORDER_RADIUS } from '../../../../utility/globals';
import { MEDIA } from '../../../../utility/media';

export const Root = styled.div`
    ${columnFlexboxFit({align: 'center'})};
    min-width: 280px;

    padding: 80px 20px 20px 20px;
`

export const Title = styled.div`
    color: ${COLORS.GRAY_PURE};
    padding: 0px 0px 4px 0px;

    font-weight: bold;
    font-size: 36px;

    ${MEDIA.lessThan('s380')`
        font-size: 24px;
    `};
`

export const SecondTitle = styled.div`
    color: ${COLORS.GRAY_SOFT_1};
    padding: 0px 0px 20px 0px;

    font-weight: bold;
    font-size: 28px;

    ${MEDIA.lessThan('s380')`
        font-size: 22px;
    `};
    ${MEDIA.lessThan('s320')`
        font-size: 18px;
    `};
`

export const ChevronIcon = styled.div`
    color: ${COLORS.GRAY_SOFT_1};
    font-size: 48px;

    ${MEDIA.lessThan('s380')`
        font-size: 32px;
    `};

    padding: 0px 0px 0px 0px;
`

export const ImageContainer = styled.div`
    padding: 0px 0px 0px 0px;

    justify-content: center;
    align-items: center;
    display: flex;

    max-width: 560px;
    width: 50%;

`

export const ActionItemRoot = styled.div`
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;

    height: 110px;
    width: 100%;

    ${MEDIA.lessThan('s380')`
        font-size: 20px;
    `};
`

export const LoadingBarPlaceHolder = styled.div`
    background-color: ${COLORS.GREEN_PALE};
    border-radius: ${BORDER_RADIUS.CIRCLE};

    max-width: 500px;
    width: 100%;

    height: 20px;

    ${MEDIA.lessThan('s380')`
        height: 12px;
    `};


`

interface LoadingBarProps {
    width: string;
};

export const LoadingBar = styled.div<LoadingBarProps>`
    background-color: ${COLORS.GREEN_DEEP};
    border-radius: ${BORDER_RADIUS.CIRCLE};

    width: ${({width}) => `${width}`};
    height: 100%;
`

export const Loading = styled.div`
    color: ${COLORS.GREEN_DEEP};

    font-weight: bold;
    font-size: 32px;

    ${MEDIA.lessThan('s380')`
        font-size: 20px;
    `};
`

export const PricingRoot = styled.div`

    padding: 16px 0px 0px 0px;
    height: 50px;

    max-width: 500px;
    width: 100%;

    text-align: center;
    font-size: 14px;

    ${MEDIA.lessThan('s380')`
        font-size: 11px;
    `};
`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
