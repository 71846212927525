import * as A from "./styled";

export const InitialBudgetReady = () => {


    return (
        <A.Root>
            <A.Title>
                Your Budget is Being Setup
            </A.Title>
        </A.Root>
    );
};

export default InitialBudgetReady;