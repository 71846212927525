import ButtonCustomTk from "../button-custom/main";
import * as A from "./styled";

export enum ButtonTextThemesTk {
    primary      = 'primary',
    primaryLink  = 'primary-link',
    subtle       = 'subtle',
    cta          = 'cta',
};

export interface Props {
    children: any;
    theme: ButtonTextThemesTk;

    onClick: () => void;
}

export const ButtonTextTk = ({children, theme, onClick}: Props) => {
    
    return (
        <A.Root className={`theme-${theme}`} onClick={onClick}>
            <ButtonCustomTk onClick={onClick}>
                {children}
            </ButtonCustomTk>
        </A.Root>
    );
};

export default ButtonTextTk;


