import { flexboxFit } from '../../../../../../utility/css-flexbox';
import { COLORS } from '../../../../../../utility/colors';
import { MEDIA } from '../../../../../../utility/media';
import styled from 'styled-components';

export const Root = styled.div`
    ${flexboxFit({justify: 'space-between', align: 'center'})};
    height: 24px;
    width: 100%;
`

export const Text = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    padding: 0px 8px 0px 0px;
    font-size: 12px;
    height: 24px;
`

export const Icon = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    font-size: 16px;
    height: 24px;

    color: ${COLORS.GREEN_DEEP};
`



// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
