
export const insertTransaction = (state: any, t: any) => {
    const transactions = state.transactions;

    // If it is the first transaction - just push
    if (transactions.length === 0) {
        transactions.push(t);
        return;
    };

    // if it has a newer date than any other transaction - just add to the head
    if (t.date > transactions[0].date) {
        state.transactions.splice(0, 0, t);
        return;
    }

    // otherwise add to the bottom of the correct blob
    var tIndex = 0;
    state.transactions.forEach((transaction: any, index: number) => {
        if (t.date <= transaction.date) {
            tIndex = index + 1;
        }
    });

    state.transactions.splice(tIndex, 0, t);
};