import * as A from "./styled";

export interface Props {
    stepNumber: any;
};

export const Header = ({stepNumber}: Props) => {

    const getNodeState = (nodeNumber: any) => {

        if (nodeNumber > stepNumber) {
            return "inactive";
        }
        return "active";
    }

    const getConnectorState = (connectorNumber: any) => {
        if (connectorNumber > stepNumber) {
            return "inactive";
        }
        return "active";
    }

    const getNode = (state: any, symbol: any, description: any) => (
        <A.NodeRoot>
            <A.NodeShape state={state}>
                {symbol}
            </A.NodeShape>
            <A.NodeDescription state={state}>
                {description}
            </A.NodeDescription>
        </A.NodeRoot>
    );

    const getConnector = (state: any) => (
        <A.ConnectorRoot>
            <A.Connector state={state}/>
        </A.ConnectorRoot>
    );

    return (
        <A.Root>
            <A.StepsRoot>
                {getNode(getNodeState(1), 1, "Signup")}
                {getConnector(getConnectorState(2))}
                {getNode(getNodeState(2), 2, "Payment")}
                {getConnector(getConnectorState(3))}
                {getNode(getNodeState(3), 3, "Create Budget")}
                {/* {getNode(getNodeState(1), 1, "About You")}
                {getConnector(getConnectorState(2))}
                {getNode(getNodeState(2), 2, "Prepare Budget")}
                {getConnector(getConnectorState(3))}
                {getNode(getNodeState(3), 3, "Payment")} */}
            </A.StepsRoot>
        </A.Root>
    );
};

export default Header;