import styled from 'styled-components';
import { flexboxFit } from '../../../utility/css-flexbox';
import { BORDER_RADIUS } from '../../../utility/globals';
import { COLORS } from '../../../utility/colors';
import { FONTS } from '../../../utility/fonts';
import { MEDIA } from '../../../utility/media';

export const Root = styled.div`
    ${flexboxFit({ justify: 'center', align: 'center' })};

    font-family: ${FONTS.ROBOTO};
    font-size: 16px;
    font-weight: bold;

    padding: 10px 30px;
    width: fit-content;
    height: 44px;

    cursor: pointer;

    ${MEDIA.lessThan('s360')`
        padding: 8px 20px;
        font-size: 14px;
    `};

    ${MEDIA.lessThan('s310')`
        padding: 8px 12px;
        font-size: 13px;
        height: 32px;
    `};

    &:hover {
        background-color: ${COLORS.GREEN_SOFT};
    }

    &:active {
        background-color: ${COLORS.GREEN_DEEP};
    }

    &.theme-primary {
        background-color: ${COLORS.GREEN_DEEP};
        color: ${COLORS.WHITE_PURE};
        border-radius: ${BORDER_RADIUS.STANDARD};
        &:hover {
            background-color: ${COLORS.GREEN_NORM};
        }
    }

    &.theme-primary-link {
        color: ${COLORS.WHITE_PURE};
        border-radius: ${BORDER_RADIUS.STANDARD};
        &:hover {
            color: ${COLORS.WHITE_SOFT};
            text-decoration: underline;
        }
    }

    &.theme-subtle {
        background-color: ${COLORS.WHITE_PURE};
        color: ${COLORS.GREEN_DEEP};
        border: 2px solid ${COLORS.GREEN_DEEP};
        border-radius: ${BORDER_RADIUS.STANDARD};
        &:hover {
            background-color: ${COLORS.WHITE_SOFT};
        }
    }

    &.theme-cta {
        background-color: ${COLORS.GREEN_DEEP};
        color: ${COLORS.WHITE_PURE};
        border-radius: ${BORDER_RADIUS.STANDARD};
        &:hover {
            background-color: ${COLORS.GREEN_NORM};
        }
    }
`;
