import { callProtectedApi } from "./api-util";

export type newAccount = {
    name: string,
    description: string,
    value: number
}

// @POST url-tag: /account
export const create = async (accessToken: string, account: newAccount) => {
    const response = await callProtectedApi(accessToken, `POST`, `/account`, JSON.stringify(account));
    return response;
}

// @GET url-tag: /account/{aid}
export const get = (accessToken: string, aid: string) => {
    return callProtectedApi(accessToken, `GET`, `/account/${aid}`, null);
}

// @PUT url-tag: /account/{aid}
export const update = (accessToken: string, aid: string, account: any) => {
    return callProtectedApi(accessToken, `PUT`, `/account/${aid}`, JSON.stringify(account));
}

// @DELETE url-tag: /account/{aid}
export const remove = (accessToken: string, aid: string) => {
    return callProtectedApi(accessToken, `DELETE`, `/account/${aid}`, null);
}