import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

interface AppError {
    message: string;
}

interface Message {
    text: string;
}
  
interface ApiResponse {
    data: Message | null;
    error: AppError | null;
}

const callExternalApi = async (options: {config: AxiosRequestConfig;}): Promise<ApiResponse> => {
  
    try {
        const response: AxiosResponse = await axios(options.config);
        const { data } = response;

        return {
            data,
            error: null,
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;

            const { response } = axiosError;

            let message = "http request failed";

            if (response && response.statusText) {
                message = response.statusText;
            }

            if (axiosError.message) {
                message = axiosError.message;
            }

            if (response && response.data && (response.data as AppError).message) {
                message = (response.data as AppError).message;
            }

            return {
                data: null,
                error: {
                    message,
                },
            };
        }

        return {
            data: null,
            error: {
                message: (error as Error).message,
            },
        };
    }
};

export const callProtectedApi = async (accessToken: string, method: string, url: string, body: any): Promise<ApiResponse> => {
    const config = {
        url: `${process.env.REACT_APP_API_SERVER_URL}${url}`, 
        method: `${method}`,
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: `${body}`,
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
}