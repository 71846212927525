import InitialBudgetSetup from "../pages/prompt-pages/initial-budget-setup/main";
import InitialBudgetReady from "../pages/prompt-pages/initial-budget-ready/main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import ReactGA4 from "react-ga4";

const AccountApp = React.lazy(() => import(/* webpackChunkName: "account-app" */ "../pages/budget-easy/apps/account-app/main"));
const BudgetApp = React.lazy(() => import(/* webpackChunkName: "budget-app" */ "../pages/budget-easy/apps/budget-app/main"));

const LandingPage = React.lazy(() => import(/* webpackChunkName: "landing-page" */ "../pages/landing-page-new/main"));
const GuidesPage = React.lazy(() => import(/* webpackChunkName: "guides-page" */ "../pages/guides-page/main"));

const Privacy = React.lazy(() => import(/* webpackChunkName: "privacy-page" */ "../pages/privacy/main"));
const Terms = React.lazy(() => import(/* webpackChunkName: "terms-page" */ "../pages/terms/main"));

// blogs pages
// ###START_BLOG_IMPORT_INJECTION
const Blog00000HowToBudgetWith702010                                  = React.lazy(() => import(/* webpackChunkName: "blog-00000-how-to-budget-70-20-10"                                      */ "../pages/blogs/blog-00000-how-to-budget-70-20-10/main"                                          ));
const Blog00001FiveEasyToUseBudgetingMethods                          = React.lazy(() => import(/* webpackChunkName: "blog-00001-five-easy-to-use-budgeting-methods"                          */ "../pages/blogs/blog-00001-five-easy-to-use-budgeting-methods/main"                              ));
const Blog00002HowToBudgetWith503020                                  = React.lazy(() => import(/* webpackChunkName: "blog-00002-how-to-budget-with-50-30-20"                                 */ "../pages/blogs/blog-00002-how-to-budget-with-50-30-20/main"                                     ));
const Blog00003BudgetingBasicsForBeginners                            = React.lazy(() => import(/* webpackChunkName: "blog-00003-budgeting-basics-for-beginners"                              */ "../pages/blogs/blog-00003-budgeting-basics-for-beginners/main"                                  ));
const Blog00004HowToTrackYourSpendingEffectively                      = React.lazy(() => import(/* webpackChunkName: "blog-00004-how-to-track-your-spending-effectively"                      */ "../pages/blogs/blog-00004-how-to-track-your-spending-effectively/main"                          ));
const Blog00005SettingAchievingFinancialGoals                         = React.lazy(() => import(/* webpackChunkName: "blog-00005-setting-achieving-financial-goals"                           */ "../pages/blogs/blog-00005-setting-achieving-financial-goals/main"                               ));
const Blog00006PsychologyOfSpendingAndBudgeting                       = React.lazy(() => import(/* webpackChunkName: "blog-00006-psychology-of-spending-and-budgeting"                        */ "../pages/blogs/blog-00006-psychology-of-spending-and-budgeting/main"                            ));
const Blog00007EffectiveDebtManagement                                = React.lazy(() => import(/* webpackChunkName: "blog-00007-effective-debt-management-strategies"                        */ "../pages/blogs/blog-00007-effective-debt-management-strategies/main"                            ));
const Blog00008EmergencyFundGuide                                     = React.lazy(() => import(/* webpackChunkName: "blog-00008-emergency-fund-guide"                                        */ "../pages/blogs/blog-00008-emergency-fund-guide/main"                                            ));
const Blog00009BudgetFriendlyMealPlanning                             = React.lazy(() => import(/* webpackChunkName: "blog-00009-budget-friendly-meal-planning"                               */ "../pages/blogs/blog-00009-budget-friendly-meal-planning/main"                                   ));
const Blog00010FinancialPlanningLifeEvents                            = React.lazy(() => import(/* webpackChunkName: "blog-00010-financial-planning-life-events"                              */ "../pages/blogs/blog-00010-financial-planning-life-events/main"                                  ));
const Blog00011InvestingOnABudget                                     = React.lazy(() => import(/* webpackChunkName: "blog-00011-investing-on-a-budget"                                       */ "../pages/blogs/blog-00011-investing-on-a-budget/main"                                           ));
// ###END_BLOG_IMPORT_INJECTION

// export url handles
export const getHomePageUrl         = ()                           => ( '/'                         );
export const getGuidesPageUrl       = ()                           => ( '/guides'                   );
export const getBlogPageUrl         = (name: string)               => ( '/blog/' + name             );

export const getBudgetSetupUrl      = ()                           => ( '/budgetsetup'              );
export const getBudgetReadyUrl      = ()                           => ( '/budgetready'              );

export const getMyBudgetPageUrl     = ()                           => ( '/mybudget'                 );
export const getAccountPageUrl      = (aid: string, params='')     => ( '/account/'  + aid + params );
export const getColumnPageUrl       = (cid: string, params='')     => ( '/column/'   + cid + params );

export const getMyAccountPageUrl    = ()                           => ( '/user/myaccount'           ); 

export const AppRouter = () => {

    useEffect(() => {
        ReactGA4.initialize("G-RZNT8T0WQV"); 
    });

    return (
        <BrowserRouter>
            <Suspense>
                <Routes>
                    <Route path="/"                                                element={ <LandingPage/>                                  } />
                    <Route path="/privacy"                                         element={ <Privacy/>                                      } />
                    <Route path="/terms"                                           element={ <Terms/>                                        } />

                    <Route path="/budgetsetup"                                     element={ <InitialBudgetSetup/>                           } />
                    <Route path="/budgetready"                                     element={ <InitialBudgetReady/>                           } />

                    <Route path="/mybudget"                                        element={ <BudgetApp/>                                    } />
                    <Route path="/account/:aid"                                    element={ <AccountApp/>                                   } />
                    <Route path="/column/:cid"                                     element={ <AccountApp/>                                   } />

                    <Route path="/guides"                                          element={ <GuidesPage/>                                   } />

                    {/* ###START_BLOG_ROUTE_INJECTION */}
                    <Route path="/blog/how-to-budget-70-20-10"                            element={ <Blog00000HowToBudgetWith702010 />                          } />
                    <Route path="/blog/five-easy-to-use-budgeting-methods"                element={ <Blog00001FiveEasyToUseBudgetingMethods />                  } />
                    <Route path="/blog/how-to-budget-with-50-30-20"                       element={ <Blog00002HowToBudgetWith503020 />                          } />
                    <Route path="/blog/budgeting-basics-for-beginners"                    element={ <Blog00003BudgetingBasicsForBeginners />                    } />
                    <Route path="/blog/how-to-track-your-spending-effectively"            element={ <Blog00004HowToTrackYourSpendingEffectively />              } />
                    <Route path="/blog/setting-achieving-financial-goals"                 element={ <Blog00005SettingAchievingFinancialGoals />                 } />
                    <Route path="/blog/psychology-of-spending-and-budgeting"              element={ <Blog00006PsychologyOfSpendingAndBudgeting />               } />
                    <Route path="/blog/effective-debt-management-strategies"              element={ <Blog00007EffectiveDebtManagement />                        } />
                    <Route path="/blog/emergency-fund-guide"                              element={ <Blog00008EmergencyFundGuide />                             } />
                    <Route path="/blog/budget-friendly-meal-planning"                     element={ <Blog00009BudgetFriendlyMealPlanning />                     } />
                    <Route path="/blog/financial-planning-life-events"                    element={ <Blog00010FinancialPlanningLifeEvents />                    } />
                    <Route path="/blog/investing-on-a-budget"                             element={ <Blog00011InvestingOnABudget />                             } />
                    {/* ###END_BLOG_ROUTE_INJECTION */}
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRouter;