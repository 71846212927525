import { callProtectedApi } from "./api-util";

export type newColumn = {
    aid: string,
    name: string,
    description: string,
    value: number
}

// @POST url-tag: /column
export const create = async (accessToken: string, column: newColumn) => {
    const response = await callProtectedApi(accessToken, `POST`, `/column`, JSON.stringify(column));
    return response;
}

// @GET url-tag: /column/{cid}
export const get = (accessToken: string, cid: string) => {
    return callProtectedApi(accessToken, `GET`, `/column/${cid}`, null);
}

// @PUT url-tag: /column/{cid}
export const update = (accessToken: string, cid: string, column: any) => {
    return callProtectedApi(accessToken, `PUT`, `/column/${cid}`, JSON.stringify(column));
}

// @DELETE url-tag: /column/{cid}
export const remove = (accessToken: string, cid: string) => {
    return callProtectedApi(accessToken, `DELETE`, `/column/${cid}`, null);
}