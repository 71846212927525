import * as A from "./styled";

export interface Props {
    children: any;
    onClick: () => void;
}

export const ButtonCustomTk = ({children, onClick}: Props) => {
    
    const wrappedOnClick = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        onClick();
    }
    
    return (
        <A.Root onClick={wrappedOnClick}>
            {children}
        </A.Root>
    )
}

export default ButtonCustomTk;


