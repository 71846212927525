export const COLORS = {
    GREEN_DEEP:   'rgb(58, 130, 87)',   // #3A8257
    GREEN_NORM:   'rgb(91, 184, 121)',  // #5BB879
    GREEN_SOFT:   'rgb(120, 206, 132)', // #78CE84
    GREEN_PALE:   'rgb(194, 233, 206)', // #C2E9CE

    RED_PALE:     'rgb(255, 163, 172)', // #FFA3AC
    RED_NORM:     'rgb(140, 25, 25)',   // #8C1919

    GRAY_SOFT_3:  'rgb(136, 156, 170)', // #889CAA
    GRAY_SOFT_2:  'rgb(126, 136, 150)', // #7E8896
    GRAY_SOFT_1:  'rgb(82, 98, 108)',   // #52626C
    GRAY_SOFT:    'rgb(55, 68, 89)',    // #374459
    GRAY_NORM:    'rgb(36, 45, 60)',    // #242D3C
    GRAY_PURE:    'rgb(20, 20, 20)',    // #141414

    WHITE_PURE:   'rgb(255, 255, 255)', // #FFFFFF
    WHITE_LIGHT:  'rgb(245, 245, 248)', // #F5F5F8
    WHITE_SOFT:   'rgb(232, 235, 240)', // #E8EBF0

    GOLD_NORM:    'rgb(255, 210, 0)',   // #FFD200
};



// export const COLORS = {
//     GREEN_DEEP:   'rgb(43, 103, 119)',  // #2b6777
//     GREEN_NORM:   'rgb(62, 139, 147)',  // #3E8B93
//     GREEN_SOFT:   'rgb(105, 166, 160)', // #69A6A0
//     GREEN_PALE:   'rgb(200, 216, 228)', // #c8d8e4

//     RED_PALE:     'rgb(255, 179, 187)', // #ffb3bb
//     RED_NORM:     'rgb(160, 30, 30)',   // #a01e1e

//     GRAY_SOFT_3:  'rgb(153, 170, 180)', // #99aab4
//     GRAY_SOFT_2:  'rgb(140, 150, 160)', // #8c96a0
//     GRAY_SOFT_1:  'rgb(92, 110, 122)',  // #5c6e7a
//     GRAY_SOFT:    'rgb(61, 76, 99)',    // #3d4c63
//     GRAY_NORM:    'rgb(40, 50, 65)',    // #283241
//     GRAY_PURE:    'rgb(0, 0, 0)',       // #000000

//     WHITE_PURE:   'rgb(255, 255, 255)', // #ffffff
//     WHITE_LIGHT:  'rgb(240, 240, 244)', // #e2e6e7
//     WHITE_SOFT:   'rgb(226, 230, 235)', // #e2e6e7

//     GOLD_NORM:    'rgb(255, 215, 0)',   // #FFD700
// };

// export const COLORS = {
//     DARKPURPLE_SOFT: 'rgb(120,0,255)',   // #7800FF
//     DARKPURPLE_NORM: 'rgb(100,0,220)',   // #6400DC
//     DARKPURPLE_DEEP: 'rgb(70,0,180)',    // #4600B4

//     BLUE_DEEP: 'rgb(0 60 190)',          // #003CBE
//     BLUE_NORM: 'rgb(3 96 252)',          // #0360FC
//     BLUE_SOFT: 'rgb(4 203 253)',         // #04CBFD
//     BLUE_PALE: 'rgb(201 242 255)',       // #C9F2FF

//     PURPLE_DEEP: 'rgb(198,204,225)',     // #C6CCE1
//     PURPLE_NORM: 'rgb(217 221 235)',     // #D9DDEB
//     PURPLE_SOFT: 'rgb(236 238 245)',     // #ECEEF5

//     GREEN_DEEP: 'rgb(0,150,120)',        // #009678
//     GREEN_NORM: 'rgb(10, 190, 30)',      // #0ABE1E
//     GREEN_SOFT: 'rgb(38,220,180)',       // #26DCA9
//     GREEN_PALE: 'rgb(202 241 231)',      // #CAF1E7

//     ORANGE_DEEP: 'rgb(238 70 9)',        // #EE4609
//     ORANGE_NORM: 'rgb(247 103 51)',      // #F76733

//     RED_PALE: 'rgb(254 238 241)',        // #FEEEF1
//     RED_NORM: 'rgb(250 30 70)',          // #FA1E46

//     GRAY_SOFT_6: 'rgb(150,160,170)',     // #96A0AA
//     GRAY_SOFT_5: 'rgb(130,140,150)',     // #828C96
//     GRAY_SOFT_2: 'rgb(110,120,130)',     // #6E7890
//     GRAY_SOFT_3: 'rgb(90,100,110)',      // #5A646E
//     GRAY_SOFT_2: 'rgb(64,80,100)',       // #405064
//     GRAY_SOFT:   'rgb(48,60,75)',        // #303C4B
//     GRAY_NORM:   'rgb(32,40,50)',        // #202832
//     GRAY_DEEP:   'rgb(16,20,25)',        // #101419
//     GRAY_PURE:   'rgb(0,0,0)',           // #000000

//     WHITE_PURE: 'rgb(255,255,255)',      // #FFFFFF
//     WHITE_SOFT: 'rgb(233 237 248)',      // #E9EDF8
//     WHITE_NORM: 'rgb(211 219 241)',      // #D3DBF1
//     WHITE_DEEP: 'rgb(189,201,234)',      // #BDC9EA
// };