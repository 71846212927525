
export const SIZE_GLOBALS = {
    NAVBAR_WIDTH: '350px',
}

export const BORDER_RADIUS = {
    SHARP:      '0px',
    MINOR:      '2px',
    STANDARD:   '4px',
    ROUNDED:    '8px',
    CIRCLE:   '999px',
};