import ButtonTextTk, { ButtonTextThemesTk } from "../../../../../toolkit/buttons/button-text/main";
import { useScreenLessThan } from "../../../../../utility/utils";
import * as A from "./styled";
import Feature from "./feature/main";

export interface Props {
    recommended: any;
    title: String;
    price: String;
    checkout: () => any;
};


export const Plan = ({recommended, title, price, checkout}: Props) => {

    const screenLt = useScreenLessThan();
    const getImageSize = () => {
        if (screenLt.screenLt300) { return 200; }
        if (screenLt.screenLt350) { return 250; }
        if (screenLt.screenLt400) { return 300; }
        if (screenLt.screenLt450) { return 350; }
        if (screenLt.screenLt500) { return 400; }
        if (screenLt.screenLt550) { return 450; }
        return 510;
    }
    const getImageWidth = () => {
        return `${getImageSize()}px`;
    }
    const getImageHeight = () => {
        return `${getImageSize() * 0.666667 }px`;
    }

    const getBilledText = () => {
        if (title == "Annual") {
            return "annually"
        }
        return title.toLowerCase();
    }

    const getTitleValue = () => {
        if (title == "Annual") {
            return "Best Value"
        }
        return "Standard";
    }

    const getFeatures = () => {
        if (title == "Annual") {
            return (
                <A.FeaturesRoot>
                    <Feature text="Customised budget design" />
                    <Feature text="Unlimited goal setting" />
                    <Feature text="Unlimited automation" />
                    <Feature text="Unlimited devices" />
                    <Feature text="Cancel any time" />
                </A.FeaturesRoot>
            )
        }
        return (
            <A.FeaturesRoot>
                <Feature text="Customised budget design" />
                <Feature text="Unlimited goal setting" />
                <Feature text="Unlimited automation" />
                <Feature text="Unlimited devices" />
                <Feature text="Cancel any time" />
            </A.FeaturesRoot>
        )
    }

    const getCheckoutButton = () => {
        if (title == "Annual") {
            return "Start Now";
        }
        return "Start Now";
    }

    return (
        <A.Root recommended={recommended}>
            <A.Title>
                {title}
                <A.TitleValue>
                    {getTitleValue()}
                </A.TitleValue>
            </A.Title>
            <A.PriceRoot>
                <A.PriceSymbol>
                    $
                </A.PriceSymbol>
                <A.PriceValue>
                    {price}
                </A.PriceValue>
                <A.PricePeriod>
                    / Month
                </A.PricePeriod>
            </A.PriceRoot>
            {getFeatures()}
            <A.BilledMessage>
                Billed {getBilledText()} <br />
                30 days free
            </A.BilledMessage>
            <ButtonTextTk theme={ButtonTextThemesTk.cta} onClick={checkout}>
                {getCheckoutButton()}
            </ButtonTextTk>
        </A.Root>
    );
};

export default Plan;