import { generateMedia } from 'styled-media-query'

export const SIZES = {
  S300:    '300',
  S310:    '310',
  S320:    '320',
  S330:    '330',
  S340:    '340',
  S350:    '350',
  S360:    '360',
  S370:    '370',
  S380:    '380',
  S390:    '390',

  S400:    '400',
  S410:    '410',
  S420:    '420',
  S430:    '430',
  S440:    '440',
  S450:    '450',
  S460:    '460',
  S470:    '470',
  S480:    '480',
  S490:    '490',

  S500:    '500',
  S510:    '510',
  S520:    '520',
  S530:    '530',
  S540:    '540',
  S550:    '550',
  S560:    '560',
  S570:    '570',
  S580:    '580',
  S590:    '590',

  S600:    '600',
  S610:    '610',
  S620:    '620',
  S630:    '630',
  S640:    '640',
  S650:    '650',
  S660:    '660',
  S670:    '670',
  S680:    '680',
  S690:    '690',

  S700:    '700',
  S710:    '710',
  S720:    '720',
  S780:    '780',
  S840:    '840',
  S900:    '900',
  S960:    '960',
  S1020:  '1020', 
  S1080:  '1080',
  S1140:  '1140',
  S1200:  '1200',
  S1260:  '1260',
  S1320:  '1320',
  S1380:  '1380',
  S1440:  '1440',
  S1500:  '1500',
  S1560:  '1560',
};

export const MEDIA = generateMedia({
  s300:    `calc(${SIZES.S300}px - 1px)`,
  s310:    `calc(${SIZES.S310}px - 1px)`,
  s320:    `calc(${SIZES.S320}px - 1px)`,
  s330:    `calc(${SIZES.S330}px - 1px)`,
  s340:    `calc(${SIZES.S340}px - 1px)`,
  s350:    `calc(${SIZES.S350}px - 1px)`,
  s360:    `calc(${SIZES.S360}px - 1px)`,
  s370:    `calc(${SIZES.S370}px - 1px)`,
  s380:    `calc(${SIZES.S380}px - 1px)`,
  s390:    `calc(${SIZES.S390}px - 1px)`,

  s400:    `calc(${SIZES.S400}px - 1px)`,
  s410:    `calc(${SIZES.S410}px - 1px)`,
  s420:    `calc(${SIZES.S420}px - 1px)`,
  s430:    `calc(${SIZES.S430}px - 1px)`,
  s440:    `calc(${SIZES.S440}px - 1px)`,
  s450:    `calc(${SIZES.S450}px - 1px)`,
  s460:    `calc(${SIZES.S460}px - 1px)`,
  s470:    `calc(${SIZES.S470}px - 1px)`,
  s480:    `calc(${SIZES.S480}px - 1px)`,
  s490:    `calc(${SIZES.S490}px - 1px)`,

  s500:    `calc(${SIZES.S500}px - 1px)`,
  s510:    `calc(${SIZES.S510}px - 1px)`,
  s520:    `calc(${SIZES.S520}px - 1px)`,
  s530:    `calc(${SIZES.S530}px - 1px)`,
  s540:    `calc(${SIZES.S540}px - 1px)`,
  s550:    `calc(${SIZES.S550}px - 1px)`,
  s560:    `calc(${SIZES.S560}px - 1px)`,
  s570:    `calc(${SIZES.S570}px - 1px)`,
  s580:    `calc(${SIZES.S580}px - 1px)`,
  s590:    `calc(${SIZES.S590}px - 1px)`,

  s600:    `calc(${SIZES.S600}px - 1px)`,
  s610:    `calc(${SIZES.S610}px - 1px)`,
  s620:    `calc(${SIZES.S620}px - 1px)`,
  s630:    `calc(${SIZES.S630}px - 1px)`,
  s640:    `calc(${SIZES.S640}px - 1px)`,
  s650:    `calc(${SIZES.S650}px - 1px)`,
  s660:    `calc(${SIZES.S660}px - 1px)`,
  s670:    `calc(${SIZES.S670}px - 1px)`,
  s680:    `calc(${SIZES.S680}px - 1px)`,
  s690:    `calc(${SIZES.S690}px - 1px)`,

  s700:    `calc(${SIZES.S700}px - 1px)`,
  s710:    `calc(${SIZES.S710}px - 1px)`,
  s720:    `calc(${SIZES.S720}px - 1px)`,
  s780:    `calc(${SIZES.S780}px - 1px)`,
  s840:    `calc(${SIZES.S840}px - 1px)`,
  s900:    `calc(${SIZES.S900}px - 1px)`,
  s960:    `calc(${SIZES.S960}px - 1px)`,
  s1020:  `calc(${SIZES.S1020}px - 1px)`,
  s1080:  `calc(${SIZES.S1080}px - 1px)`,
  s1140:  `calc(${SIZES.S1140}px - 1px)`,
  s1200:  `calc(${SIZES.S1200}px - 1px)`,
  s1260:  `calc(${SIZES.S1260}px - 1px)`,
  s1320:  `calc(${SIZES.S1320}px - 1px)`,
  s1380:  `calc(${SIZES.S1380}px - 1px)`,
  s1440:  `calc(${SIZES.S1440}px - 1px)`,
  s1500:  `calc(${SIZES.S1500}px - 1px)`,
  s1560:  `calc(${SIZES.S1560}px - 1px)`,
});