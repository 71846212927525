

const setJustify = (justify?: string) => `${justify && `justify-content: ${justify}`}`
const setAlign = (align?: string) => `${align && `align-items: ${align}`}`

interface FlexboxProps {
    justify?: string;
    align?: string;
}

export const flexboxFit = ({justify, align}: FlexboxProps) => `
    ${setJustify(justify)};
    ${setAlign(align)};

    display: flex;
`

export const flexboxFill = ({justify, align}: FlexboxProps) => `
    ${setJustify(justify)};
    ${setAlign(align)};

    display: flex;

    height: 100%;
    width: 100%;
`

export const columnFlexboxFit = ({justify, align}: FlexboxProps) => `
    ${setJustify(justify)};
    ${setAlign(align)};

    flex-direction: column;
    display: flex;
`

export const columnFlexboxFill = ({justify, align}: FlexboxProps) => `
    ${setJustify(justify)};
    ${setAlign(align)};

    flex-direction: column;
    display: flex;

    height: 100%;
    width: 100%;
`