import { useScreenLessThan } from "../../../../utility/utils";
import * as A from "./styled";
import Plan from "./plan/main";
import ImageJpg from "./payment.jpg";
import { redirectToAnnualCheckout, redirectToMonthlyCheckout } from "../../../budget-easy/app-core/util";

export interface Props {
    user: any;
};

export const Payments = ({user}: Props) => {

    const screenLt = useScreenLessThan();
    const getImageSize = () => {
        if (screenLt.screenLt300) { return 100; }
        if (screenLt.screenLt350) { return 120; }
        if (screenLt.screenLt400) { return 140; }
        if (screenLt.screenLt450) { return 160; }
        if (screenLt.screenLt500) { return 180; }
        if (screenLt.screenLt550) { return 200; }
        return 220;
    }
    const getImageWidth = () => {
        return `${getImageSize()}px`;
    }
    const getImageHeight = () => {
        return `${getImageSize()}px`;
    }

    const annualCheckout = () => {
        redirectToAnnualCheckout(user?.stripe_customer_id);
    };

    const monthlyCheckout = () => {
        redirectToMonthlyCheckout(user?.stripe_customer_id);
    };

    return (
        <A.Root>
            <A.Title>
                You're Almost Done!
            </A.Title>
            {/* <A.Title>
                Enjoy Your First 30 Days Free
            </A.Title> */}
            <A.Description>
                Choose The Perfect Plan For You
            </A.Description>

            <A.PlanRoot>
                <Plan recommended={true} title="Annual" price="4.99" checkout={annualCheckout}/>
                <A.Spacer />
                <Plan recommended={false} title="Monthly" price="6.99" checkout={monthlyCheckout}/>
            </A.PlanRoot>

            <A.FinePrint>
                Payments will be made automatically at the end of the 30 day trial.
            </A.FinePrint>

            {/* <A.ImageContainer>
                <img src={ImageJpg} alt="product" width={getImageWidth()} height={getImageHeight()} />
            </A.ImageContainer> */}
        </A.Root>
    );
};

export default Payments;