// import 'bootstrap/dist/css/bootstrap.css';            // DO NOT REMOVE - used throughout the app
import './index.css';

import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';

import AppRouter from './app-router/main';
import store from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={String(process.env.REACT_APP_AUTH0_DOMAIN)}
        clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
        redirectUri={`${window.location.origin}/mybudget`}
        audience={String(process.env.REACT_APP_AUTH0_AUDIENCE)}
        cacheLocation="localstorage"
        useRefreshTokens={true}
      >
        <AppRouter />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
