import Stripe from 'stripe';

const STRIPE_SECRET_KEY = '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'

const MONTHLY_PRICE_14_99 = 'price_1Od77cKmnzImaJwuVzypXbM2';    // 14.99 USD / Month
const ANNUAL_PRICE_9_99 = 'price_1Od732KmnzImaJwu05W3oNbN';      //  9.99 USD / Month - 119.88 USD / Year

const MONTHLY_PRICE_6_99 = 'price_1PMVq3KmnzImaJwunQ0TZCjc';     //  6.99 USD / Month
const ANNUAL_PRICE_4_99 = 'price_1PMVobKmnzImaJwupDBNLTnz';      //  4.99 USD / Month - 59.99 USD / Year

const MONTHLY_PRICE_1_99 = 'price_1P5HwtKmnzImaJwuH6PzuUDq';     //  1.99 USD / Month
const ANNUAL_PRICE_0_99 = 'price_1P5HwGKmnzImaJwuymb4DZ9b';      //  0.99 USD / Month - 11.88 USD / Year



// ****************************************************************************

export const redirectToMonthlyCheckout = async (customerId: string) => {
    await redirectToCheckout(customerId, MONTHLY_PRICE_6_99);
};

// ****************************************************************************

export const redirectToAnnualCheckout = async (customerId: string) => {
    await redirectToCheckout(customerId, ANNUAL_PRICE_4_99);
};

// ****************************************************************************

export const redirectToCheckout = async (customerId: string, price: string) => {
    const stripe = new Stripe(STRIPE_SECRET_KEY, {apiVersion: '2023-08-16'});

    const origin = window.location.origin;

    const session = await stripe.checkout.sessions.create({
        success_url: `${origin}/mybudget`,
        cancel_url: `${origin}`,
        customer: customerId,
        line_items: [
            {price: price, quantity: 1},
        ],
        mode: 'subscription',
        subscription_data: {
            trial_period_days: 30,
         },
         allow_promotion_codes: true,
    });

    window.location.replace(String(session.url));  
};

// ****************************************************************************