import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiBudget from "../../api/api-budget";
import * as apiAccount from "../../api/api-account";
import * as apiColumn from "../../api/api-column";
import * as apiTransaction from "../../api/api-transaction";
import * as stripe from '../../stripe/util';
import { insertTransaction } from './util';

const initialState: any = {};

// ****************************************************************************
// GET SUBSCRIPTION STATUS
export const thunkGetSubscriptionStatus = createAsyncThunk(
    'user/getSubscriptionStatus',
    async (data: any) => {
        return (await stripe.getSubscriptionStatus(data.stripeCustomerId)) as any;
    } 
);

// ****************************************************************************
// GET PROJECT OVERVIEW

export const thunkGetAll = createAsyncThunk(
    'budget/getAll', 
    async (data: any) => {
        return (await apiBudget.getAll(data.accessToken)) as any;
    }

);

// ****************************************************************************
// CREATE ACCOUNT

export const thunkCreateAccount = createAsyncThunk(
    'project/createAccount',
    async (data: any) => {

        const newAccount = {
            name: data.name,
            description: data.description,
            value: 0,
        }

        return (await apiAccount.create(data.accessToken, newAccount));
    }
);

// ****************************************************************************
// EDIT ACCOUNT

export const thunkEditAccount = createAsyncThunk(
    'project/editAccount',
    async (data: any) => {

        const account = {
            name: data.name,
            description: data.description,
        }
        
        return (await apiAccount.update(data.accessToken, data.object._id, account));
    }
);

// ****************************************************************************
// DELETE ACCOUNT

export const thunkDeleteAccount = createAsyncThunk(
    'project/deleteAccount',
    async (data: any) => {

        return (await apiAccount.remove(data.accessToken, data.object._id));
    }
)

// ****************************************************************************
// CREATE COLUMN

export const thunkCreateColumn = createAsyncThunk(
    'project/createColumn',
    async (data: any) => {

        const newColumn = {
            aid: data.navAid,

            name: data.name,
            description: data.description,
            value: data.value,

            goal: data.goal,
            autoPayments: data.autoPayments
        }

        return (await apiColumn.create(data.accessToken, newColumn));
    }
);

// ****************************************************************************
// EDIT COLUMN

export const thunkEditColumn = createAsyncThunk(
    'project/editColumn',
    async (data: any) => {

        const column = {
            aid: data.navAid,

            name: data.name,
            description: data.description,
            value: data.value,

            goal: data.goal,
            autoPayments: data.autoPayments
        }

        return (await apiColumn.update(data.accessToken, data.object._id, column));
    }
);

// ****************************************************************************
// DELETE COLUMN

export const thunkDeleteColumn = createAsyncThunk(
    'project/deleteColumn',
    async (data: any) => {

        return (await apiColumn.remove(data.accessToken, data.object._id));
    }
)

// ****************************************************************************
// CREATE TRANSACTION

export const thunkCreateTransaction = createAsyncThunk(
    'project/createTransaction',
    async (data: any) => {

        const newTransaction = {
            aid: data.navAid,

            date: data.date,
            cid: data.newCid,
            name: data.name,
            description: data.description,
            value: data.newValue,
        }

        return (await apiTransaction.create(data.accessToken, newTransaction));
    }
);

// ****************************************************************************
// EDIT TRANSACTION

export const thunkEditTransaction = createAsyncThunk(
    'project/editTransaction',
    async (data: any) => {

        const transaction = {
            aid: data.navAid,

            date: data.date,
            newCid: data.newCid,
            oldCid: data.oldCid,
            name: data.name,
            description: data.description,
            newValue: data.newValue,
            oldValue: data.oldValue,
        }

        return (await apiTransaction.update(data.accessToken, data.object._id, transaction));
    }
);

// ****************************************************************************
// DELETE TRANSACTION

export const thunkDeleteTransaction = createAsyncThunk(
    'project/deleteTransaction',
    async (data: any) => {

        return (await apiTransaction.remove(data.accessToken, data.object._id));
    }
)

// ****************************************************************************
// BUILD PROJECT SLICE

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
    },
    extraReducers(builder) {

        // ************************************************
        // USER REDUCERS

        builder.addCase(thunkGetSubscriptionStatus.fulfilled, (state: any, action: any) => {
            state.subscriptionStatus = action?.payload?.status;
        });

        // ************************************************
        // PROJECT REDUCERS

        builder.addCase(thunkGetAll.fulfilled, (state: any, action: any) => {
            state.uid = action?.payload?.data?.uid;
            state.accounts = action?.payload?.data?.accounts;
            state.columns = action?.payload?.data?.columns;
            state.transactions = action?.payload?.data?.transactions;
        });

        // ************************************************
        // ACCOUNT REDUCERS

        builder.addCase(thunkCreateAccount.fulfilled, (state: any, action: any) => {
            state.accounts.push(action.payload.data);
        });

        builder.addCase(thunkEditAccount.fulfilled, (state: any, action: any) => {
            const index = state.accounts.findIndex((account: any) => account._id === action.payload.data._id);
            state.accounts[index] = action.payload.data;
        });

        builder.addCase(thunkDeleteAccount.fulfilled, (state: any, action: any) => {
            const index = state.accounts.findIndex((account: any) => account._id === action.payload.data._id);
            state.accounts.splice(index, 1);
        });

        // ************************************************
        // COLUMN REDUCERS

        builder.addCase(thunkCreateColumn.fulfilled, (state: any, action: any) => {
            state.columns.push(action.payload.data);
        });

        builder.addCase(thunkEditColumn.fulfilled, (state: any, action: any) => {
            const index = state.columns.findIndex((column: any) => column._id === action.payload.data._id);
            state.columns[index] = action.payload.data;
        });

        builder.addCase(thunkDeleteColumn.fulfilled, (state: any, action: any) => {
            const cid = action.payload.data._id;

            const index = state.columns.findIndex((column: any) => column._id === cid);
            state.columns.splice(index, 1);

            // remove the transactions associated with that column from the state
            state.transactions = state.transactions.filter((t: any) => t.cid !== cid);
        });

        // ************************************************
        // TRANSACTION REDUCERS

        builder.addCase(thunkCreateTransaction.fulfilled, (state: any, action: any) => {
            const transaction = action.payload.data;

            // add the transaction to the state
            insertTransaction(state, transaction);

            // update the column to the new value
            const cIndex = state.columns.findIndex((c: any) => c._id === transaction.cid);
            state.columns[cIndex].value += transaction.value;

        });

        builder.addCase(thunkEditTransaction.fulfilled, (state: any, action: any) => {
            const transactionResponse = action.payload.data;
            const transaction = {
                _id: transactionResponse._id,
                aid: transactionResponse.aid,
                date: transactionResponse.date,
                cid: transactionResponse.newCid,
                name: transactionResponse.name,
                description: transactionResponse.description,
                value: transactionResponse.newValue,
            };

            // update the transaction to the new value
            const index = state.transactions.findIndex((t: any) => t._id === transaction._id);
            state.transactions[index] = transaction;

            // update both affected columns
            const newColumnIndex = state.columns.findIndex((c: any) => c._id === transactionResponse.newCid);
            state.columns[newColumnIndex].value += transactionResponse.newValue;

            const oldColumnIndex = state.columns.findIndex((c: any) => c._id === transactionResponse.oldCid);
            state.columns[oldColumnIndex].value -= transactionResponse.oldValue;
        });

        builder.addCase(thunkDeleteTransaction.fulfilled, (state: any, action: any) => {
            const transaction = action.payload.data;

            // remove the transaction from the state
            const transactionIndex = state.transactions.findIndex((t: any) => t._id === action.payload.data._id);
            state.transactions.splice(transactionIndex, 1);

            // update the column to the new value
            const columnIndex = state.columns.findIndex((c: any) => c._id === transaction.cid);
            state.columns[columnIndex].value -= transaction.value;

        });
    }
});

export default projectSlice.reducer;