import { columnFlexboxFit, flexboxFit } from '../../../../utility/css-flexbox';
import { COLORS } from '../../../../utility/colors';
import styled from 'styled-components';
import { BORDER_RADIUS } from '../../../../utility/globals';

export const Root = styled.div`
    ${columnFlexboxFit({justify: 'center', align: 'center'})};
    max-width: 520px;
    min-width: 280px;
    width: 100%;
`

export const StepsRoot = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    width: 100%;
`
export const NodeRoot = styled.div`
    ${columnFlexboxFit({justify: 'center', align: 'center'})};

    min-width: 80px;
    width: 80px;

    z-index: 1;
`

interface Props {
    state: string;
};

export const NodeShape = styled.div<Props>`
    ${columnFlexboxFit({justify: 'center', align: 'center'})};

    border-radius: ${BORDER_RADIUS.CIRCLE};
    ${({state}) => (
        state === 'active'  ? `border: 2px solid ${COLORS.GREEN_DEEP}` : 
        state === 'inactive' ? `border: 2px solid ${COLORS.GREEN_PALE}` :
        ''
    )};

    ${({state}) => (
        state === 'active'  ? `color: ${COLORS.GREEN_DEEP}` : 
        state === 'inactive' ? `color: ${COLORS.GREEN_PALE}` :
        ''
    )};

    font-size: 18px;
    height: 30px;
    width: 30px;

`

export const NodeDescription = styled.div<Props>`
    ${({state}) => (
        state === 'active'  ? `color: ${COLORS.GRAY_PURE}` : 
        state === 'inactive' ? `color: ${COLORS.GREEN_PALE}` :
        ''
    )};
    padding-top: 8px;

    font-weight: bold; 
    font-size: 9px;
`

export const ConnectorRoot = styled.div`
    padding-top: 15px;
    height: 100%;
    width: 100%;
`

export const Connector = styled.div<Props>`
    ${({state}) => (
        state === 'active'  ? `border: 1px solid ${COLORS.GREEN_DEEP}` : 
        state === 'inactive' ? `border: 1px solid ${COLORS.GREEN_PALE}` :
        ''
    )};

    width: calc(100% + 52px);

    margin-left: -26px;
`

export const ImageContainer = styled.div`
    padding: 0px 0px 0px 0px;

    justify-content: center;
    align-items: center;
    display: flex;

    max-width: 560px;
    width: 50%;
`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
