import { MdCheckCircle } from "react-icons/md";
import * as A from "./styled";
import { BsDot } from "react-icons/bs";

export interface Props {
    text: any;
};


export const Feature = ({text}: Props) => {

    return (
        <A.Root>
            <A.Text>
                {text}
            </A.Text>
            <A.Icon>
                <MdCheckCircle/>
            </A.Icon>
        </A.Root>
    );
};

export default Feature;