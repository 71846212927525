import { columnFlexboxFit, flexboxFit } from '../../../../../utility/css-flexbox';
import { COLORS } from '../../../../../utility/colors';
import { MEDIA } from '../../../../../utility/media';
import styled from 'styled-components';
import { BORDER_RADIUS } from '../../../../../utility/globals';

export const Root = styled.div`
    ${flexboxFit({align: 'center'})};
    width: 100%;

    height: 70px;

    border: 1px solid ${COLORS.GRAY_SOFT};
    border-radius: ${BORDER_RADIUS.STANDARD};

    padding: 1px;

    &:hover{
        padding: 0px;
        border: 2px solid ${COLORS.GREEN_DEEP}; 
        cursor: pointer;
    }
`

export const IconRoot = styled.div`
    ${flexboxFit({justify: 'center'})};
    
    padding: 8px;
    height: 100%;
    width: 50px;
`

export const Icon = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    background-color: ${COLORS.GRAY_SOFT};
    color: ${COLORS.WHITE_PURE};

    border-radius: ${BORDER_RADIUS.CIRCLE};
    font-size: 18px;

    height: 28px;
    width: 28px;
`

export const TextRoot = styled.div`
    padding: 8px;

    font-size: 14px;
    height: 100%;
    width: 100%;
`

export const Title = styled.div`
    color: ${COLORS.GRAY_SOFT};
    font-weight: bold;

    ${MEDIA.lessThan('s460')`
        font-size: 14px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 13px;
    `};
`

export const Text = styled.div`
    color: ${COLORS.GRAY_SOFT_1};
    font-size: 12px;

    ${MEDIA.lessThan('s460')`
        font-size: 11px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 10px;
    `};
`

export const CheckBoxRoot = styled.div`
    ${flexboxFit({justify: 'center'})};

    padding: 8px;
    height: 100%;
    width: 50px;
`

export const CheckBox = styled.div`
    ${flexboxFit({justify: 'center'})};
    color: ${COLORS.GREEN_DEEP};

    border: 1px solid ${COLORS.GREEN_DEEP};
    border-radius: ${BORDER_RADIUS.STANDARD};
    
    font-size: 22px;
    height: 24px;
    width: 24px;

    padding: 0.5px;
    &:hover{
        border: 1.5px solid ${COLORS.GREEN_DEEP}; 
        cursor: pointer;
        padding: 0px;
    }

`



export const ImageContainer = styled.div`
    padding: 0px 0px 0px 0px;

    justify-content: center;
    align-items: center;
    display: flex;

    max-width: 560px;
    width: 50%;

`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
