import { BaseSyntheticEvent } from "react";
import * as A from './styled';

// ****************************************************************************
// PROPS

export interface Props {
    value: string;
    theme?: string;
    onChange: (val: string) => void;
    isValid?: (val: string) => boolean;
};

// ****************************************************************************
// MAIN COMPONENT

const alwaysValid = () => {
    return true;
}

export const InputTextTk = ({value, theme='default', onChange, isValid=alwaysValid}: Props) => {

    const onChangeWrapper = (event: BaseSyntheticEvent) => {
        const val = event.target.value;
        if(isValid(val)) {
            onChange(val);
        };
    };

    const inputProps = {
        value: value,
        textType: 'text',
        onChange: onChangeWrapper,
    }

    return <A.TextInput className={`theme-${theme}`} {...inputProps}/>;
};

export default InputTextTk;