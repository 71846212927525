import { columnFlexboxFit, flexboxFit } from '../../../../../utility/css-flexbox';
import { COLORS } from '../../../../../utility/colors';
import { MEDIA } from '../../../../../utility/media';
import styled from 'styled-components';
import { BORDER_RADIUS } from '../../../../../utility/globals';

interface Props {
    recommended: any;
};

export const Root = styled.div<Props>`
    ${columnFlexboxFit({justify: 'space-around', align: 'center'})};
    
    border: 2px solid ${COLORS.GREEN_DEEP};
    border-radius: ${BORDER_RADIUS.ROUNDED};
    box-sizing: content-box;
    box-shadow: 4px 4px 2px rgb(240, 240, 240);

    min-height: 400px;
    width: 240px;

    cursor: pointer;
    transition: box-shadow, 0.3s, transform, 0.3s;

    &:hover{
        box-shadow: 4px 4px 2px rgb(220, 220, 220);
        transform: scale(1.02);
        transition: box-shadow, 0.3s, transform, 0.3s;
    }
`

export const Title = styled.div`
    ${columnFlexboxFit({justify: 'center', align: 'center'})};
    color: ${COLORS.GRAY_PURE};

    font-weight: bold;
    font-size: 24px;

    ${MEDIA.lessThan('s460')`
        font-size: 18px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 16px;
    `};
`

export const TitleValue = styled.div`
    color: ${COLORS.GRAY_SOFT};

    font-style: italic;
    font-weight: normal;
    text-align: center;
    font-size: 16px;

    margin-top: -4px;

    ${MEDIA.lessThan('s460')`
        font-size: 14px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 12px;
    `};
`

export const PriceRoot = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    height: 36px;
`

export const PriceSymbol = styled.div`
    ${flexboxFit({justify: 'start', align: 'start'})};
    height: 100%;
`

export const PriceValue = styled.div`
    ${flexboxFit({justify: 'center', align: 'center'})};
    height: 100%;
    
    font-weight: bold;
    font-size: 36px;
`

export const PricePeriod = styled.div`
    ${flexboxFit({justify: 'end', align: 'end'})};
    padding-left: 4px;
    height: 100%;
`

export const FeaturesRoot = styled.div`
    padding: 0px 20px 0px 20px;
`

export const BilledMessage = styled.div`
    color: ${COLORS.GRAY_SOFT};

    text-align: center;
    font-size: 12px;
`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
