import { columnFlexboxFit } from '../../../../utility/css-flexbox';
import { COLORS } from '../../../../utility/colors';
import { MEDIA } from '../../../../utility/media';
import styled from 'styled-components';

export const Root = styled.div`
    ${columnFlexboxFit({justify: 'center'})};
    max-width: 520px;
    min-width: 280px;
    width: 100%;

    padding-bottom: 40px;
    padding-left: 15px;
    padding-top: 40px;
`

export const Title = styled.div`
    color: ${COLORS.GRAY_PURE};

    font-weight: bold;
    font-size: 24px;

    ${MEDIA.lessThan('s460')`
        font-size: 20px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 20px;
    `};
`

export const Description = styled.div`
    color: ${COLORS.GRAY_SOFT_1};
    
    font-weight: bold;
    font-size: 14px;

    ${MEDIA.lessThan('s460')`
        font-size: 12px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 10px;
    `};
`

export const SectionTitle = styled.div`
    color: ${COLORS.GRAY_SOFT};

    padding-top: 20px;
    font-weight: bold;
    font-size: 24px;

    ${MEDIA.lessThan('s460')`
        font-size: 18px;
    `};
    ${MEDIA.lessThan('s380')`
        font-size: 16px;
    `};
`

export const InputValueContainer = styled.div`
    padding-right: 16px;
    padding-top: 8px;
`

export const ImageContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;

    margin-bottom: -16px;
`

export const IconContainer = styled.div`
    padding: 0px 0px 0px 8px;
    font-size: 18px;
`

export const VerticalSpacer =styled.div`
    height: 8px;
`

// export const HeroImage = styled.div`
//     background: url(${heroImage});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;

//     display: flex;

//     height: 250px;
//     width: 250px;

//     ${MEDIA.lessThan('s720')`
//         margin-bottom: 30px;
//         height: 200px;
//         width: 200px;
//     `};

//     ${MEDIA.greaterThan('s1080')`
//         height: 350px;
//         width: 350px;
//     `};

//     border: 2px solid ${COLORS.WHITE_PURE};
//     border-radius: 50px;
// `
